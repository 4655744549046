import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformComponent } from 'react-zoom-pan-pinch';

import { FileLayout } from '../FileLayout';
import { FileDriver } from '.';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const EmptyFragmentRenderer = () => <></>;

const PDFDriver: FileDriver = ({ onLoaded, isLoading, filePath }) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  const onLoadSuccess = (numPages: number) => {
    onLoaded();
    setNumPages(numPages);
  };

  return (
    <FileLayout isLoading={isLoading} filePath={filePath}>
      <TransformComponent>
        <Document
          file={filePath}
          onLoadSuccess={(proxy) => onLoadSuccess(proxy.numPages)}
          loading={EmptyFragmentRenderer}
        >
          {Array.from(new Array(numPages), (_, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </TransformComponent>
    </FileLayout>
  );
};

export default PDFDriver;
